import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import usePaymentPages from '../../hooks/usePaymentPages';
import { getPaymentLinkDetailsWithPayCode } from '../../redux/payment-pages/paymentPagesActions';
import TextInput from '../../components/inputs/TextInput';
import NumericInput from '../../components/inputs/NumericInput';
import Button from '../../components/buttons/Button';
import { paymentValidation } from '../../validation/landing/landingValidation';
import { toastWarning, toastSuccess } from "../../helpers/utils.toast";
import { GLADE_SUPPORTED_CARDS } from "../../assets/images/images";
import { isArray, isEmpty } from '../../helpers/helper';

export default function Landing() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const paymentPages = usePaymentPages(); 

  const encodedUrl = 'https://pay-test.glade.ng/stripe-status?logo=https%3A%2F%2Fcheckout-testing.glade.ng%2Finline%2Fimg%2Flogo-symbol.png&amp;title=Fashion+school&amp;payment_method%5B0%5D=card&amp;MID=GP_83c83ad792b74fb3a011ffbbbdc02b2c&amp;amount=1000.00&amp;firstname=dami&amp;lastname=dadajdn&amp;email=adadjh%40dhda.adja&amp;currency=NGN&amp;country=NG&amp;business_name=Eeji&amp;payment_category=paylink&amp;category_item_id=130&amp;description=Fashion&amp;meta_data=%5B%7B%22mid%22%3A%22GP_83c83ad792b74fb3a011ffbbbdc02b2c%22%2C%22id%22%3A%22Fashion+school%22%2C%22name%22%3A%22Fashion+school%22%2C%22value%22%3A%221000.00%22%2C%22currency%22%3A%22USD%22%2C%22type%22%3A%22event_data%22%7D%5D&amp;bearer=customer&amp;fee=284.88&payment_intent=pi_3LqFI0LnSf6067Ml0d0O1ryu&payment_intent_client_secret=pi_3LqFI0LnSf6067Ml0d0O1ryu_secret_qjXo7Cu7CheDYt0MKeOHFKsZG&redirect_status=succeeded';

  React.useEffect(() => {
    if (params.code) {
      dispatch(getPaymentLinkDetailsWithPayCode({ paycode: params.code }));
    }
    // navigate('/stripe-status', { state: { urlString: encodedUrl}});
    // eslint-disable-next-line 
  }, []);

  const [ticket, setTicket] = React.useState({});
  const [input, setInput] = React.useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    amount: paymentPages.paymentLinkDetail?.amount ?? 0,
    currency: paymentPages.paymentLinkDetail?.meta_data?.currency ?? "",
  });

  React.useEffect(() => {
    setInput({
      ...input,
      amount: paymentPages.paymentLinkDetail?.amount ?? 0,
      currency: paymentPages.paymentLinkDetail?.meta_data?.currency ?? "",
    });
    // eslint-disable-next-line 
  }, [paymentPages.paymentLinkDetail]);

  React.useEffect(() => {
    let calculatedAmount = 0;
    for (const item in ticket) {
      calculatedAmount = calculatedAmount + ((ticket[item].value ?? 0) * (ticket[item].qty ?? 0));
    }

    setInput({ ...input, amount: calculatedAmount });
    // eslint-disable-next-line 
  }, [ticket]);

  const onSubmit = () => {
    debugger
    console.log("Environment : " + process.env.REACT_APP_APP_ENV);
    // Sort meta data
    let metaData = '';

    if (paymentPages.paymentLinkDetail?.meta_data?.is_ticket === 1) {
      let ticketData = Object.keys(ticket).map((item, key) => {
        return {
          id: key,
          name: ticket[item]?.name,
          value: ticket[item]?.value,
          qty: ticket[item]?.qty,
          type: "ticket_data"
        }
      });
      metaData = JSON.stringify(ticketData);
    }
 
    if (paymentPages.paymentLinkDetail?.meta_data?.is_ticket === 0) {
      metaData = JSON.stringify([{
        mid: paymentPages.paymentLinkDetail?.mid,
        id: paymentPages.paymentLinkDetail?.title,
        name: paymentPages.paymentLinkDetail?.title,
        value: input.amount,
        currency: paymentPages.paymentLinkDetail?.meta_data?.currency,
        type: "event_data"
      }]);
    }

    // Validated and initialize glade pay
    if (paymentValidation(input)) {
      if (typeof window.payWithGlade !== "function") {
        return toastWarning("Glade Pay is currently unavailable, Please try again later");
      } else {
        window.payWithGlade({
          MID: paymentPages.paymentLinkDetail?.mid,
          KEY: paymentPages.paymentLinkDetail?.key,
          email: input.email,
          payment_category: 'paylink',
          category_item_id: paymentPages.paymentLinkDetail?.id,
          firstname: input.first_name,
          lastname: input.last_name,
          amount: input.amount,
          meta_data: metaData,
          currency: paymentPages.paymentLinkDetail?.meta_data?.currency,
          description: paymentPages.paymentLinkDetail?.description,
          title: paymentPages.paymentLinkDetail?.title,
          bearer: paymentPages.paymentLinkDetail?.payer_bears_fees === 1 ? 'customer' : 'account',
          environment: process.env.REACT_APP_APP_ENV !== 'production' ? process.env.REACT_APP_APP_ENV :'production',
          onclose: (err) => {
            // Return message
            toastWarning("Transaction was closed");
          },
          callback: (res) => {
            // Return message
            toastSuccess("Transaction was successful");

            if (paymentPages.paymentLinkDetail?.redirect_url) {
              navigate.push(paymentPages.paymentLinkDetail?.redirect_url);
            }
            
            // const decodedUrl = window.location.href;
            //   navigate('/stripe-status', { state: { urlString: decodedUrl }});
         
          },
        });
      }
    }
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <section className='flex flex-col justify-center'>
      <div className='grid md:grid-cols-3 gap-4 sm:grid-cols-1'>
        <div className='md:col-start-2 p-6 bg-white sm:col-start-1'>
          <div className='flex flex-col items-center space-y-3 my-3'>
            <div className='flex flex-col items-center'>
              {!isEmpty(paymentPages.paymentLinkDetail?.logo_url) &&
                <img alt="Payment Logo" src={paymentPages.paymentLinkDetail?.logo_url} style={{ width: '140px' }} />}
            </div>
            <div className='flex flex-col items-center'>
              <h3 className="glade-heading-five glade-black">{paymentPages.paymentLinkDetail?.title}</h3>
              <h6 className="glade-normal-text-two glade-black">{paymentPages.paymentLinkDetail?.description}</h6>
            </div>
          </div>

          <div className="flex flex-col space-y-3 my-3">
            <div className='flex flex-row justify-between'>
              <div className="w-full mr-3">
                <TextInput
                  label={'First Name'}
                  id={'first_name'}
                  name={'first_name'}
                  type={'text'}
                  value={input.first_name}
                  onChange={onChange}
                  placeholder={''}
                  autoComplete={'first_name'}
                  className={'appearance-none'}
                />
              </div>
              <div className="w-full">
                <TextInput
                  label={'Last Name'}
                  id={'last_name'}
                  name={'last_name'}
                  type={'text'}
                  value={input.last_name}
                  onChange={onChange}
                  placeholder={''}
                  autoComplete={'last_name'}
                  className={'appearance-none'}
                />
              </div>
            </div>
            <TextInput
              label={'Phone Number'}
              id={'phone_number'}
              name={'phone_number'}
              type={'tel'}
              value={input.phone_number}
              onChange={onChange}
              placeholder={''}
              autoComplete={'phone_number'}
              className={'appearance-none'}
            />
            <TextInput
              label={'Email Address'}
              id={'email'}
              name={'email'}
              type={'email'}
              value={input.email}
              onChange={onChange}
              placeholder={''}
              autoComplete={'email'}
              className={'appearance-none'}
            />
            {paymentPages.paymentLinkDetail?.amount_type !== 'fixed' &&
              <NumericInput
                leftSection={<span className="self-center border border-gray-300 rounded-sm p-2">{paymentPages.paymentLinkDetail?.meta_data?.currency}</span>}
                label={'Enter Amount'}
                id={"amount"}
                name={'amount'}
                type={'number'}
                min={'0'}
                value={input.amount ?? ''}
                onChange={onChange}
                placeholder={'Enter amount'}
                autoComplete={"amount"}
                className={"appearance-none"}
              />}
          </div>

          {paymentPages.paymentLinkDetail?.meta_data?.is_ticket === 1 &&
            <div className="flex flex-col space-y-3 my-3 mt-9">
              <h4 className="glade-heading-five glade-black text-center">Tickets Available</h4>
              <div className='border p-3'>

                {isArray(paymentPages.paymentLinkDetail?.meta_data?.ticket_data) && paymentPages.paymentLinkDetail?.meta_data?.ticket_data?.map((item, key) => {
                  return (
                    <div key={key} className="flex flex-row justify-between my-2">
                      <div className='flex flex-col justify-start'>
                        <h5>{item.ticket_name}</h5>
                        <h6>{paymentPages.paymentLinkDetail?.meta_data?.currency} {item.amount}</h6>
                      </div>
                      <div className='flex flex-col justify-end'>
                        <NumericInput
                          leftSection={<span className="self-center border border-gray-300 rounded-sm p-2 px-3" onClick={() => {
                            let value = ticket[key]?.qty && ticket[key]?.qty - 1 >= 0 ? ticket[key]?.qty - 1 : 0;
                            setTicket({ ...ticket, [key]: { qty: value, id: key, name: item.ticket_name, value: item.amount } });
                          }}>-</span>}
                          rightSection={<span className="self-center border border-gray-300 rounded-sm p-2 px-3" onClick={() => {
                            let value = ticket[key]?.qty ? ticket[key]?.qty + 1 : 1;
                            setTicket({ ...ticket, [key]: { qty: value, id: key, name: item.ticket_name, value: item.amount } });
                          }}>+</span>}
                          label={''}
                          id={key}
                          name={key}
                          type={'number'}
                          min={'0'}
                          value={ticket[key]?.qty}
                          onChange={(e) => { setTicket({ ...ticket, [key]: { qty: parseInt(e.target.value), id: key, name: item.ticket_name, value: item.amount } }) }}
                          placeholder={'0'}
                          autoComplete={''}
                          className={'appearance-none w-20 text-center'}
                        />
                      </div>
                    </div>
                  )
                })}

              </div>
            </div>}

          <div className="pt-3">
            <Button.Blue
              title={`Pay ${input.currency} ${input?.amount}`}
              type={'submit'}
              className={'py-3 min-w-1/3 m-auto'}
              onClick={onSubmit}
              disabled={paymentPages.isLoading}
              loading={paymentPages.isLoading}
            />
          </div>
          
          <div className='flex flex-row justify-center mt-6'>
            <img alt="Supported Cards" src={GLADE_SUPPORTED_CARDS} />
          </div>
        </div>
      </div>


    </section>
  );
}