import React from 'react'
import { Link } from "react-router-dom";
import { CloseIcon, CloseIconCircled, GladefinanceLogo } from '../../assets/icons/Icons';

const StripeFailed = ({ amount, currency }) => {
    return (
        <section className="payment-wrap">
        <div className="payment-header">
          <div>
            <GladefinanceLogo />
          </div>
          <div>
            <Link to={"/"}>
              <CloseIcon />
            </Link>
          </div>
        </div>
        <div className="payment-box">
          <div className="alert-icon">
            <CloseIconCircled />
          </div>
          <h3>Payment Failed</h3>
          <p>
            Your payment of {amount} {currency} Failed
          </p>
          <div>
            <Link to={"/"}>
              <button className="">Try Again</button>
            </Link>
          </div>
        </div>
      </section>
    )
}
export default StripeFailed;