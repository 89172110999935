import React from 'react'

import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import StripeSuccess from './StripeSuccess'
import StripeProcessing  from './StripeProcessing'
import StripeFailed from './StripeFailed'
import {stipePayment} from '../../redux/payment-pages/paymentPagesActions';

const StripeStatus = () => {

  
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = window.location.href;

  const decodedUrl = decodeURIComponent(queryParams)
  const parameters = decodedUrl.replaceAll('&amp;', '&');

  const urlParams = new URLSearchParams(parameters);

  const data = {
    payment_intent_id: urlParams.get('payment_intent'),
    amount: urlParams.get('amount'),
    currency: urlParams.get('currency'),
    mid: urlParams.get('MID'),
    fee_bearer: urlParams.get('bearer'),
    user:{email: urlParams.get('email'), firstname: urlParams.get('firstname'), lastname: urlParams.get('lastname')},
    meta: JSON.parse(urlParams.get('meta_data')),
    payment_category:urlParams.get('payment_category'),
    payment_category_id: parseInt(urlParams.get('category_item_id'))
  }



  React.useEffect(() => {
    dispatch(stipePayment(data)) 
    // eslint-disable-next-line 
  }, []);


  return (
    <>
      {urlParams.get('redirect_status') === "succeeded" && 
        <StripeSuccess currency={data.currency} amount={data.amount} />
      }
      {urlParams.get('redirect_status') === "processing" && 
        <StripeProcessing currency={data.currency} amount={data.amount}  />
      }
      {urlParams.get('redirect_status') === "failed" && 
        <StripeFailed currency={data.currency} amount={data.amount} />
      }
    </>
  )
}

export default StripeStatus