import {
  LOADING_AUTH,
  LOADING_VERIFY_USER_PIN,
  RELOAD_AUTH,
  LOGOUT_USER,
  REGISTER_USER,
  REFRESHED_TOKEN,
  TOGGLE_ACCOUNT,
  LOGIN_USER,
  STORE_USER_INFO,
  VERIFY_USER_EMAIL,
  RESEND_EMAIL_VERIFICATION,
  CREATE_PASS_CODE,
  INIT_PASSWORD_RESET,
  COMPLETE_PASSWORD_RESET,
  GET_BUSINESS_PERSONAL_ACCOUNTS,
  VERIFY_USER_PIN,
} from "./types";

const initialState = {
  isLoading: false,
  isLoadingVerifyPin: false,
  shouldReload:false,
  user: {},
  isBusiness: false,
  isBusinessUUID: undefined,
  token: "",
  refresh_token: "",
  storeUserInfo: {},
  verifyUserEmail: {},
  resendEmailVerification: {},
  createPassCode: {},
  initPasswordReset: {},
  completePasswordReset: {},
  businessAndPersonalAccounts: {},
  selectedBusinessOrPersonalAccount: {},
  verifyUserPin: {},
  hasCompliance: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case LOADING_VERIFY_USER_PIN:
      return {
        ...state,
        isLoadingVerifyPin: action.payLoad,
      };

    case RELOAD_AUTH:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case LOGIN_USER:
      return {
        ...state,
        user: action.payLoad?.data?.data,
        token: action.payLoad?.data?.data?.token,
        refresh_token: action.payLoad?.data?.data?.refresh_token,

        isBusiness: action.payLoad?.data?.data?.is_business ?? false,
        isBusinessUUID: action.payLoad?.data?.data?.business_uuid ?? undefined,
        selectedBusinessOrPersonalAccount: action.payLoad?.data?.data?.selectedBusinessOrPersonalAccount ?? {},
        hasCompliance: action.payLoad?.data?.data?.compliance_status === 'approved'? true : false,
      };

    case LOGOUT_USER:
      return {
        ...state,
        user: {},
        token: "",
      };

    case TOGGLE_ACCOUNT:
      return {
        ...state,
        user:{
          ...state.user,
          compliance_status: action.payLoad.compliance_status,
        },
        isBusiness: action.payLoad?.is_business,
        isBusinessUUID: action.payLoad?.business_uuid,
        selectedBusinessOrPersonalAccount: action.payLoad,
        hasCompliance: action.payLoad?.compliance_status === 'approved'? true : false,
      };

    case REGISTER_USER:
      return {
        ...state,
        user: {},
      };

    case REFRESHED_TOKEN:
      return {
        ...state,
        token: action.payLoad?.data?.data?.token,
        refresh_token: action.payLoad?.data?.data?.refresh_token,
      };

    case STORE_USER_INFO:
      return {
        ...state,
        storeUserInfo: action.payLoad.data.data,
      };

    case VERIFY_USER_EMAIL:
      return {
        ...state,
        verifyUserEmail: action.payLoad.data.data,
      };

    case RESEND_EMAIL_VERIFICATION:
      return {
        ...state,
        resendEmailVerification: action.payLoad.data.data,
      };

    case CREATE_PASS_CODE:
      return {
        ...state,
        createPassCode: action.payLoad.data.data,
      };

    case INIT_PASSWORD_RESET:
      return {
        ...state,
        initPasswordReset: action.payLoad.data.data,
      };

    case COMPLETE_PASSWORD_RESET:
      return {
        ...state,
        completePasswordReset: action.payLoad.data.data,
      };

    case GET_BUSINESS_PERSONAL_ACCOUNTS:
      return {
        ...state,
        businessAndPersonalAccounts: action.payLoad.data.data,
      };

    case VERIFY_USER_PIN:
      return {
        ...state,
        verifyUserPin: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
