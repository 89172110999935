import { validate } from '../../helpers/validator';
import { toast } from "react-toastify";

export const paymentValidation = (payLoad, silently=false) => {

    let data = {
        first_name:payLoad.first_name,
        last_name:payLoad.last_name,
        phone_number:payLoad.phone_number,
        email:payLoad.email,
        amount:payLoad.amount
    };
    let rules = {
        first_name:'required|minSize:1|maxSize:50',
        last_name:'required|minSize:1|maxSize:50',
        phone_number:'required|phone|minSize:9|maxSize:15',
        email:'required|email',
        amount:'required|min:-1|max:999999999999999'
    }
    let messages = {
        first_name:{
            required:'First name is required',
            minSize:'First name is too short',
            maxSize:'First name is too long'
        },
        last_name:{
            required:'Last name is required',
            minSize:'Last name is too short',
            maxSize:'Last name is too long'
        },
        phone_number:{
            required:'A phone number is required',
            phone:'Phone number is not valid',
            minSize:'Phone number is too short',
            maxSize:'Phone number is too long'
        },
        email:{
            required:'An email address is required',
            email:'Email address is not valid',
        },
        amount:{
            required:'An amount is required',
            min:'Minimum amount is 0',
            max:'Amount is not valid'
        }
    }

    // If they are no errors then exit
    let result = validate(data,rules,messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" }); 
    });

    return false;
}