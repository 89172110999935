import {
  LOADING_INVOICES,
  GET_INVOICE_DETAIL,
  MARK_INVOICE_AS_PAID,
  GET_ALL_CURRENCIES,
  GET_ALL_PAYMENT_TYPE
} from "./types";

const initialState = {
  isLoading:false,
  currencies: [],
  paymentTypes: [],
  invoiceDetail: {},
  markInvoiceAsPaid: {}
};

export default function invoiceReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_INVOICES:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case GET_ALL_CURRENCIES:
      return {
        ...state,
        currencies: action.payLoad.data.data,
      };

    case GET_ALL_PAYMENT_TYPE:
      return {
        ...state,
        paymentTypes: action.payLoad.data.data,
      };

    case GET_INVOICE_DETAIL:
      return {
        ...state,
        invoiceDetail: action.payLoad.data.data,
      };

    case MARK_INVOICE_AS_PAID:
      return {
        ...state,
        markInvoiceAsPaid: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
