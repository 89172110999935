import { useSelector } from 'react-redux';

const useInvoices = () => {
    const invoices = useSelector(state => state.invoices);

    let {
        isLoading,
        currencies,
        paymentTypes,
        invoiceDetail,
        markInvoiceAsPaid,
    } = invoices;

    return {
        isLoading,
        currencies,
        paymentTypes,
        invoiceDetail,
        markInvoiceAsPaid,
    }
};

export default useInvoices;
