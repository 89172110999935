import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {objectToFormData} from "../../helpers/helper";
import { 
  LOADING_INVOICES,
  GET_INVOICE_DETAIL,
  MARK_INVOICE_AS_PAID,
  GET_ALL_CURRENCIES,
  GET_ALL_PAYMENT_TYPE
} from "./types";

/**
 * Get payment pages currencies
 * @returns {void}
 */
export const getCurrencies = () => {
  return (dispatch) => {
    Axios.get(`payment/lookup/currencies`)
    .then((res) => {
      dispatch({ type: GET_ALL_CURRENCIES, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Get payment pages payment type
 * @returns {void}
 */
export const getPaymentTypes = () => {
  return (dispatch) => {
    Axios.get(`payment/lookup/types`)
    .then((res) => {
      dispatch({ type: GET_ALL_PAYMENT_TYPE, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Get invoice details
 * @param {object} payLoad {id:''}
 * @returns {void}
 */
 export const getInvoiceDetails = (payLoad) => {
  return (dispatch) => {
    Axios.get(`invoices/with-slug/${payLoad.id}`)
    .then((res) => {
      dispatch({ type: GET_INVOICE_DETAIL, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Activate payment pages payment link
 * @param {object} payLoad {link_id:''}
 * @returns {void}
 */
export const activatePaymentLink = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_INVOICES, payLoad:true});
    Axios.get(`payment/links/activate/${payLoad.link_id}`)
    .then((res) => {
      dispatch({ type: LOADING_INVOICES, payLoad:false});
      dispatch({ type: MARK_INVOICE_AS_PAID, payLoad:res});
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_INVOICES, payLoad:false});
      errorHandler(error, true);
    });
  }
}