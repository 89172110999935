import React from 'react'
import { Link } from "react-router-dom";
import { CloseIcon, GladefinanceLogo } from '../../assets/icons/Icons';

const StripeProcessing = ({ amount, currency }) => {
  return (
    <section className="payment-wrap">
    <div className="payment-header">
      <div>
        <GladefinanceLogo />
      </div>
      <div>
        <Link to={"/"}>
          <CloseIcon />
        </Link>
      </div>
    </div>
    <div className="payment-box">
    <div className="loader"></div>
    <h3>Payment Processing</h3>
      <p>
        Your payment of {amount} {currency} has been recieved and is being processed.
      </p>
      <div>
        <Link to={"/"}>
          <button className="">Go Home</button>
        </Link>
      </div>
    </div>
  </section>
  )
}
export default StripeProcessing