import React from "react";
import { Link } from "react-router-dom";
import {
  CircleCheck,
  CloseIcon,
  GladefinanceLogo,
} from "../../assets/icons/Icons";

const SuccessStripe = ({ amount, currency }) => {
  return (
    <section className="payment-wrap">
      <div className="payment-header">
        <div>
          <GladefinanceLogo />
        </div>
        <div>
          <Link to={"/"}>
            <CloseIcon />
          </Link>
        </div>
      </div>
      <div className="payment-box">
        <div className="alert-icon">
          <CircleCheck />
        </div>
        <h3>Payment successful</h3>
        <p>
          Your payment of {amount} {currency} was successful
        </p>
        <div>
          <Link to={"/"}>
            <button className="">Go Home</button>
          </Link>
        </div>
      </div>
    </section>
  );
};
export default SuccessStripe;
