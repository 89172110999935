import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import useInvoices from '../../hooks/useInvoices';
import { getInvoiceDetails } from '../../redux/invoice/invoiceActions';
import TextInput from '../../components/inputs/TextInput';
import NumericInput from '../../components/inputs/NumericInput';
import Button from '../../components/buttons/Button';
import { paymentValidation } from '../../validation/landing/landingValidation';
import { toastWarning, toastSuccess } from "../../helpers/utils.toast";
import { GLADE_SUPPORTED_CARDS } from "../../assets/images/images";
import { isArray, isEmpty } from '../../helpers/helper';

export default function Invoice() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const invoices = useInvoices();

  React.useEffect(() => {
    if (params.code) {
      dispatch(getInvoiceDetails({ id: params.code }));
    }
    // eslint-disable-next-line 
  }, []);
  console.log({invoices: invoices.invoiceDetail});

  const [items, setItems] = React.useState({});
  const [input, setInput] = React.useState({
    first_name: invoices.invoiceDetail?.invoice?.user_name.split(" ")[0],
    last_name: invoices.invoiceDetail?.invoice?.user_name.split(" ")[1],
    phone_number: invoices.invoiceDetail?.invoice?.phone,
    email: invoices.invoiceDetail?.invoice?.email,
    amount: invoices.invoiceDetail?.invoice?.total_value ?? 0,
    currency: invoices.invoiceDetail?.invoice?.currency ?? "",
    items: invoices.invoiceDetail?.items ?? "",
  });

  React.useEffect(() => {
    setInput({
      ...input,
      amount: invoices.invoiceDetail?.invoice?.total_value ?? 0,
      currency: invoices.invoiceDetail?.invoice?.currency ?? "",
    });
    // eslint-disable-next-line 
  }, [invoices.invoiceDetail]);

  React.useEffect(() => {
    let calculatedAmount = 0;
    for (const item in items) {
      calculatedAmount = (items[item].total_cost ?? 0);
    }

    setInput({ ...input, amount: calculatedAmount });
    // eslint-disable-next-line 
  }, [items]);

  const onSubmit = () => {
    // Sort meta data
    let metaData = '';

    metaData = JSON.stringify([{
      mid: invoices.invoiceDetail?.mid,
      id: invoices.invoiceDetail?.title,
      slug: invoices.invoiceDetail?.invoice?.slug,
      invoice_no: invoices.invoiceDetail?.invoice?.invoice_no,
      value: input.amount,
      currency: invoices.invoiceDetail?.invoice?.currency,
      items: invoices.invoiceDetail?.items
    }]);

    // Validated and initialize glade pay
    if (paymentValidation(input)) {
      if (typeof window.payWithGlade !== "function") {
        return toastWarning("Glade Pay is currently unavailable, Please try again later");
      } else {
        window.payWithGlade({
          MID: invoices.invoiceDetail?.mid,
          KEY: invoices.invoiceDetail?.key,
          email: input.email,
          payment_category: 'invoice',
          category_item_id: invoices.invoiceDetail?.invoice?.invoice_no,
          firstname: input.first_name,
          lastname: input.last_name,
          amount: input.amount,
          meta_data: metaData,
          currency: invoices.invoiceDetail?.invoice?.currency,
          description: "Make a payment to " + invoices.invoiceDetail?.business_name,
          title: "Invoice " + invoices.invoiceDetail?.invoice?.invoice_no,
          bearer: invoices.invoiceDetail?.invoice?.charge_user === 1 ? 'customer' : 'account',
          environment: process.env.REACT_APP_APP_ENV !== 'production' ? process.env.REACT_APP_APP_ENV:'production',
          onclose: (err) => {
            // Return message
            toastWarning("Transaction was closed");
          },
          callback: (res) => {
            // Return message
            toastSuccess("Transaction was successful");
            dispatch(getInvoiceDetails({ id: params.code }));

            // // Redirect to custom url if provided
            // if (paymentPages.paymentLinkDetail?.redirect_url) {
            //   navigate.push(paymentPages.paymentLinkDetail?.redirect_url);
            // }
          },
        });
      }
    }
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <section className='flex flex-col justify-center'>
      <div className='grid md:grid-cols-3 gap-4 sm:grid-cols-1'>
        <div className='md:col-start-2 p-6 bg-white sm:col-start-1'>

          <div className='flex flex-col items-center space-y-3 my-3'>
            <div className='flex flex-col items-center'>
              {!isEmpty(invoices.invoiceDetail?.business_logo) && invoices.invoiceDetail?.business_logo != null &&
                <img alt="Payment Logo" src={invoices.invoiceDetail?.business_logo} style={{ width: '140px' }} />}
            </div>
            <div className='flex flex-col items-center'>
              <h3 className="glade-heading-five glade-black">{"Invoice " + invoices.invoiceDetail?.invoice?.invoice_no}</h3>
              <h6 className="glade-normal-text-two glade-black">{"Make a payment to " + invoices.invoiceDetail?.business_name}</h6>
            </div>
          </div>

          <div className="flex flex-col space-y-3 my-3">
            <div className='flex flex-row justify-between'>
              <div className="w-full mr-3">
                <TextInput
                  label={'First Name'}
                  id={'first_name'}
                  name={'first_name'}
                  type={'text'}
                  value={input.first_name}
                  onChange={onChange}
                  placeholder={''}
                  autoComplete={'first_name'}
                  className={'appearance-none'}
                />
              </div>
              <div className="w-full">
                <TextInput
                  label={'Last Name'}
                  id={'last_name'}
                  name={'last_name'}
                  type={'text'}
                  value={input.last_name}
                  onChange={onChange}
                  placeholder={''}
                  autoComplete={'last_name'}
                  className={'appearance-none'}
                />
              </div>
            </div>
            <TextInput
              label={'Phone Number'}
              id={'phone_number'}
              name={'phone_number'}
              type={'tel'}
              value={input.phone_number}
              onChange={onChange}
              placeholder={''}
              autoComplete={'phone_number'}
              className={'appearance-none'}
            />
            <TextInput
              label={'Email Address'}
              id={'email'}
              name={'email'}
              type={'email'}
              value={input.email}
              onChange={onChange}
              placeholder={''}
              autoComplete={'email'}
              className={'appearance-none'}
            />
            {/* <NumericInput 
              leftSection={<span className="self-center border border-gray-300 rounded-sm p-2">{invoices.invoiceDetail?.invoice?.currency}</span>}
              label={'Enter Amount'}
              id={"amount"}
              name={'amount'}
              type={'number'}
              min={'0'}
              value={input.amount}
              onChange={onChange}
              placeholder={'Enter amount'}
              autoComplete={"amount"}
              className={"appearance-none"}
            /> */}
          </div>

          <div className="pt-3 flex justify-center">
            <Button.Blue
              title={invoices.invoiceDetail?.invoice?.status == 'paid' ? `Invoice has already been paid` : `Pay ${input.currency} ${input?.amount}`}
              type={'submit'}
              className={'py-3'}
              onClick={onSubmit}
              disabled={invoices.invoiceDetail?.invoice?.status == 'paid' || invoices.isLoading}
              loading={invoices.isLoading}
            />
          </div>

          <div className='flex flex-row justify-center mt-6'>
            <img alt="Supported Cards" src={GLADE_SUPPORTED_CARDS} />
          </div>
          
        </div>
      </div>
    </section>
  );
}