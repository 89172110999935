import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {objectToFormData} from "../../helpers/helper";
import { 
  LOADING_PAYMENT_LINKS,
  GET_ALL_CURRENCIES,
  GET_ALL_PAYMENT_TYPE,
  GET_ALL_PAYMENT_LINKS,
  STORE_PAYMENT_LINK,
  UPDATE_PAYMENT_LINK,
  GET_PAYMENT_LINK_TRANSACTIONS,
  GET_PAYMENT_LINK_DETAIL,
  DELETE_PAYMENT_LINK,
  DEACTIVATE_PAYMENT_LINK,
  ACTIVATE_PAYMENT_LINK,
  STRIPE_PAYMENT,
  LOADING_STRIPE_PAYMENT
} from "./types";

/**
 * Get payment pages currencies
 * @returns {void}
 */
export const getCurrencies = () => {
  return (dispatch) => {
    Axios.get(`payment/lookup/currencies`)
    .then((res) => {
      dispatch({ type: GET_ALL_CURRENCIES, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Get payment pages payment type
 * @returns {void}
 */
export const getPaymentTypes = () => {
  return (dispatch) => {
    Axios.get(`payment/lookup/types`)
    .then((res) => {
      dispatch({ type: GET_ALL_PAYMENT_TYPE, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Get payment pages payment links
 * @param {object} payLoad {type:"",currency:"",search:"",start:"",end:""}
 * @returns {void}
 */
export const getPaymentLinks = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:true});
    Axios.get(`payment/links`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:false});
      dispatch({ type: GET_ALL_PAYMENT_LINKS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Store payment links
 * @param {object} payLoad {
    title:My Fancy Payout System
    state:1
    description:payment for my fancy collections
    amount:5000
    quantity:1
    currency:null
    split:1
    split_payload:[{'split_reference': 1, 'split_type': 'percentage', 'split_share': 30}] 
    type:one_time
    frequency:monthly
    frequency_value:1
    is_fixed:1
    extra_currency:null
    extra_amount:null
    show_quantity:false
    accept_number:false
    payer_bears_fees:1
    custom_link:hala_madrista
    delete_image:
    image:
    redirect_url:
    custom_message:
    notification_email:null
    extra_fields:
    is_ticket:1
    ticket_data:[]
    event_data:[]
    ticket_currency:NGN
 * }
 * @returns {void}
 */
export const storePaymentLinks = (payLoad, callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:true});
    let formData = objectToFormData(payLoad);
    Axios.post(`payment/links`,formData,{headers:{ "Content-Type": "multipart/form-data" }})
    .then((res) => {
      dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:false});
      dispatch({ type: STORE_PAYMENT_LINK, payLoad:res});
      successHandler(res,true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Update payment links
 * @param {object} payLoad {
    link_id:''
    title:My Fancy Payout System
    state:1
    description:payment for my fancy collections
    amount:5000
    quantity:1
    currency:null
    split:1
    split_payload:[{'split_reference': 1, 'split_type': 'percentage', 'split_share': 30}] 
    type:one_time
    frequency:monthly
    frequency_value:1
    is_fixed:1
    extra_currency:null
    extra_amount:null
    show_quantity:false
    accept_number:false
    payer_bears_fees:1
    custom_link:hala_madrista
    delete_image:
    image:
    redirect_url:
    custom_message:
    notification_email:null
    extra_fields:
    is_ticket:1
    ticket_data:[]
    event_data:[]
    ticket_currency:NGN
 * }
 * @returns {void}
 */
export const updatePaymentLinks = (payLoad, callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:true});
    let formData = objectToFormData(payLoad);
    Axios.post(`payment/links/${payLoad.link_id}`,formData,{headers:{ "Content-Type": "multipart/form-data" }})
    .then((res) => {
      dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:false});
      dispatch({ type: UPDATE_PAYMENT_LINK, payLoad:res});
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get payment pages payment link transaction
 * @param {object} payLoad {link_id:''}
 * @returns {void}
 */
 export const getAllPaymentLinkTransactions = (payLoad) => {
  return (dispatch) => {
    Axios.get(`payment/links/${payLoad.link_id}/history`)
    .then((res) => {
      dispatch({ type: GET_PAYMENT_LINK_TRANSACTIONS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Get payment pages payment link details
 * @param {object} payLoad {id:''}
 * @returns {void}
 */
 export const getPaymentLinkDetails = (payLoad) => {
  return (dispatch) => {
    Axios.get(`payment/links/${payLoad.id}/detail`)
    .then((res) => {
      dispatch({ type: GET_PAYMENT_LINK_DETAIL, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Get payment pages payment link details
 * @param {object} payLoad {id:''}
 * @returns {void}
 */
 export const getPaymentLinkDetailsWithPayCode = (payLoad) => {
  return (dispatch) => {
    Axios.get(`generate/payment-link`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_PAYMENT_LINK_DETAIL, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Delete payment pages payment link
 * @param {object} payLoad {link_id:''}
 * @returns {void}
 */
 export const deletePaymentLink = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:true});
    Axios.delete(`payment/links/${payLoad.link_id}`)
    .then((res) => {
      dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:false});
      dispatch({ type: DELETE_PAYMENT_LINK, payLoad:res});
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Deactivate payment pages payment link
 * @param {object} payLoad {link_id:''}
 * @returns {void}
 */
export const deactivatePaymentLink = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:true});
    Axios.delete(`payment/links/deactivate/${payLoad.link_id}`)
    .then((res) => {
      dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:false});
      dispatch({ type: DEACTIVATE_PAYMENT_LINK, payLoad:res});
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Activate payment pages payment link
 * @param {object} payLoad {link_id:''}
 * @returns {void}
 */
export const activatePaymentLink = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:true});
    Axios.get(`payment/links/activate/${payLoad.link_id}`)
    .then((res) => {
      dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:false});
      dispatch({ type: ACTIVATE_PAYMENT_LINK, payLoad:res});
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYMENT_LINKS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Stripe Payment
 * @param {object} payLoad {
      "payment_intent_id":"pi_3LpMF4LnSf6067Ml0pT0Mash",
    "amount":500,
    "currency":"USD",
    "mid":"GP_49894a738ef1479d89d5f82b28b1a21e",
    "fee_bearer":"customer",
    "user":{"email":"larryjmpk@gmail.com","firstname":"larry","lastname":"josh"},
    "meta":[{"mid":"GP_49894a738ef1479d89d5f82b28b1a21e","id":"Josh","name":"Josh","value":"500.00","currency":"USD","type":"event_data"}],
    "payment_category":"paylink",
    "payment_category_id":109
 * }
 * @returns {void}
 */
    export const stipePayment = (payLoad, callBack=()=>{}) => {
      return (dispatch) => {
        dispatch({ type: LOADING_STRIPE_PAYMENT, payLoad:true});
        Axios.post(`stripe-payment`, payLoad)
        .then((res) => {
          dispatch({ type: LOADING_STRIPE_PAYMENT, payLoad:false});
          dispatch({ type: STRIPE_PAYMENT, payLoad:res});
          successHandler(res,true);
          callBack();
        })
        .catch((error) => {
          dispatch({ type: LOADING_STRIPE_PAYMENT, payLoad:false});
          errorHandler(error, true);
        });
      }
    }