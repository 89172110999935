// Application types
export const LOADING_AUTH = 'LOADING_AUTH';
export const LOADING_VERIFY_USER_PIN = 'LOADING_VERIFY_USER_PIN';
export const LOADING_RESOLVE_BVN = 'LOADING_RESOLVE_BVN';
export const RELOAD_AUTH = 'RELOAD_AUTH';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const TOGGLE_ACCOUNT = 'TOGGLE_ACCOUNT';
export const REGISTER_USER = 'REGISTER_USER';
export const REFRESHED_TOKEN = 'REFRESHED_TOKEN';
export const STORE_USER_INFO = 'STORE_USER_INFO';
export const VERIFY_USER_EMAIL = 'VERIFY_USER_EMAIL';
export const RESEND_EMAIL_VERIFICATION = 'RESEND_EMAIL_VERIFICATION';
export const CREATE_PASS_CODE = 'CREATE_PASS_CODE';
export const INIT_PASSWORD_RESET = 'INIT_PASSWORD_RESET';
export const COMPLETE_PASSWORD_RESET = 'COMPLETE_PASSWORD_RESET';
export const GET_BUSINESS_PERSONAL_ACCOUNTS = 'GET_BUSINESS_PERSONAL_ACCOUNTS';
export const VERIFY_USER_PIN = 'VERIFY_USER_PIN';
export const RESOLVE_BVN = 'RESOLVE_BVN';