import { destroyLastLogin, updatePersistedLastLogin } from '../../helpers/session';
import { LOGOUT_USER, REFRESHED_TOKEN} from './types';

export const logoutUserLocallyAction = () => {
    return (dispatch) => {
        dispatch({type: LOGOUT_USER, payLoad: {}});
        destroyLastLogin();
        localStorage.clear();
    }
}

export const refreshUserTokenLocallyAction = (payLoad) => {
    return (dispatch) => {
        updatePersistedLastLogin(payLoad);
        dispatch({type: REFRESHED_TOKEN, payLoad: payLoad});
    }
}