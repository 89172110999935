import React from "react";

import { useLocation } from "react-router-dom";
import VisaFailed from "../../components/visa/VisaFailed";
import VisaSuccess from "../../components/visa/VisaSuccess";
import VisaProcessing from "../../components/visa/VisaProcessing";

const VisaStatus = () => {
  const location = useLocation();

  const queryParams = window.location.href;

  const decodedUrl = decodeURIComponent(queryParams);
  const parameters = decodedUrl.replaceAll("&amp;", "&");

  const urlParams = new URLSearchParams(parameters);
  

  const data = {
    status: urlParams.get("status"),
    amount: urlParams.get("amount"),
    currency: urlParams.get("currency"),
  };

  return (
    <>
      {urlParams.get("status") === 200 && (
        <VisaSuccess currency={data.currency} amount={data.amount} />
      )}
      {urlParams.get("txnStatus") === "successful" && (
        <VisaSuccess currency={data.currency} amount={data.amount} />
      )}
      {urlParams.get("txnStatus") === "processing" && (
        <VisaProcessing currency={data.currency} amount={data.amount} />
      )}
      {urlParams.get("txnStatus") === "error" && (
        <VisaFailed currency={data.currency} amount={data.amount} />
      )}
      {urlParams.get("status") === 400 && (
        <VisaFailed currency={data.currency} amount={data.amount} />
      )}
      {urlParams.get("txnStatus") === "failed" && (
        <VisaFailed currency={data.currency} amount={data.amount} />
      )}
    </>
  );
};

export default VisaStatus;
