import React from "react";

// Toasts Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Router
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";

// pages without layouts
import Landing from "./pages/common/Landing";
import Invoice from "./pages/common/Invoice";
import VisaStatus from "./pages/common/VisaStatus";
import StripeStatus from "./pages/common/StripeStatus";

function App() {
  return (
    <Provider store={store}>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop/>
      <BrowserRouter>
        <Routes>
          {/* add routes without layouts */}
          <Route path="/:code" exact element={<Landing />} />

          <Route path="/invoice/:code" exact element={<Invoice />} />

          <Route path="/stripe-status" exact element={<StripeStatus />} />
          <Route path="/VisaStatus" exact element={<VisaStatus />} />

          {/* add redirect for first page */}
          <Route path="*" element={<Navigate to="/:code" replace />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;