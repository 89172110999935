import { useSelector } from 'react-redux';

const usePaymentPages = () => {
    const paymentPages = useSelector(state => state.paymentPages);

    let {
        isLoading,
        currencies,
        paymentTypes,
        paymentLinkIndex,
        storePaymentLink,
        updatePaymentLink,
        paymentLinkTransaction,
        paymentLinkDetail,
        deletePaymentLink,
    } = paymentPages;

    return {
        isLoading,
        currencies,
        paymentTypes,
        paymentLinkIndex,
        storePaymentLink,
        updatePaymentLink,
        paymentLinkTransaction,
        paymentLinkDetail,
        deletePaymentLink,
    }
};

export default usePaymentPages;
